import React from 'react';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as Next } from './next.svg';

const opportunitiesData = {
	opportunities: [
		{
			title: 'HostJane for Ukraine',
			description:
				'Made with Ukrainian artist Olga Shtonda, Ukrainian vocalist Olha Lishchyshyn, and Edinburgh busker Eliza Rose, HostJane for Ukraine is a music album to raise funds for the Ukrainian Women\'s Fund.',
			nextIcon: <Next />,
			titleLink: '/hostjaneforukraine',
			internal: true,
		},
		{
			title: 'JANE JOBS',
			description:
				'Aimed at freelancers with the skills for larger projects and regular, long-term employment, JANE JOBS is a Discord community to learn, grow and connect with clients.',
			nextIcon: <Next />,
			titleLink: 'https://discord.gg/5rS6Tvd',
			internal: false,
		},
		{
			title: 'Ideas on Bluesky',
			description:
				'HostJane shares stories, tips and profiles from freelancers who survived Covid and used our platform to turn thin air into cash - to create value from a $0 balance.',
			nextIcon: <Next />,
			titleLink: 'https://bsky.app/profile/hostjane.com',
			internal: false,
		},
		{
			title: 'Tutorials',
			description:
				'Build, learn and scale your project with comprehensive guides written by trusted developers.',
			nextIcon: <Next />,
			titleLink: 'https://help.hostjane.com/vps-hosting/',
			internal: false,
		},
		{
			title: 'Help for Buyers and Sellers',
			description:
				"Get access to a library of comprehensive docs for VPS hosting. HostJane's SSD-powered Intel virtual machines offer better pricing and web hosting technology.",
			nextIcon: <Next />,
			titleLink: 'https://help.hostjane.com',
			internal: false,
		},
		{
			title: 'Telegram Channel',
			description:
				"HostJane's official Telegram for updates and company news from the marketplace.",
			nextIcon: <Next />,
			titleLink: 'https://t.me/hostjanecom',
			internal: false,
		},
		{
			title: 'Agreements',
			description:
				"Equality and transparency are core to HostJane's values required for our goals. Explore how our marketplace rules underpin our community's transactions.",
			nextIcon: <Next />,
			titleLink: '/legal/tos',
			internal: true,
		},
		{
			title: 'Sitemap',
			description:
				"We champion open-source technology. We structured our platform from early stage to help buyers connect with the world's best freelancers.",
			nextIcon: <Next />,
			titleLink: '/sitemap',
			internal: true,
		},
	],
	btnText: 'Follow HostJane',
	btnIcon: <TwitterIcon />,
	btnLink: 'https://x.com/hostjane',
	internal: false,
};

export default opportunitiesData;
