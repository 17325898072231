import React from 'react';
import { ReactComponent as FreelancersIcon } from './freelancers.svg';
import { ReactComponent as WebHostingIcon } from './web-hosting.svg';

export default {
	title: 'Save time and money.',
	leftColumn: {
		title: 'Hire talent',
		icon: FreelancersIcon,
		description: 'Bring projects to life. It\'s free to join.',
		ctas: [
			{
				type: 'primary',
				label: 'Find people',
				link: '/marketplace',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Earn cash today',
				link: 'https://www.hostjane.com/sell',
				internal: false,
			},
		],
	},
	rightColumn: {
		title: 'Host your site',
		icon: WebHostingIcon,
		description: 'VPS & Cloud built on Amazon EC2.',
		ctas: [
			{
				type: 'primary',
				label: 'Buy now',
				link: 'https://cloud.hostjane.com',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Customer Portal',
				link: 'https://cloud.hostjane.com/hosting/login',
				internal: true,
			},
		],
	},
};
