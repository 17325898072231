const tutorialsData = [
	{
		title: 'MUSICIANS',
		description:
			'Awesome music lessons on Google Meet',
		link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./tutor.jpg'),
	},
	{
		title: 'ONLINE TUTORS',
		description:
			'Learn a new language on Zoom',
		link: 'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./noe132.png'),
	},
	{
		title: 'DIGITAL MARKETING',
		description:
			'SEO and link building consultants',
		link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./seo.jpg'),
	},
	{
		title: 'SOFTWARE DEVELOPERS',
		description:
			'Find full-stack developers to assist you',
		link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./amaannadeem.jpeg'),
	},
	{
		title: 'WORDPRESS EXPERTS',
		description:
			'Find WordPress and PHP developers',
		link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./codetile.jpg'),
	},
	{
		title: 'CREATIVE WRITING',
		description:
			'Hire wordsmiths crafting stories',
		link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./jess_1550256623.png'),
	},
	{
		title: 'WEB DESIGNERS',
		description:
			'Figma wireframes to Dribbble experts',
		link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/web-design-apps',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./outsourceindia.jpg'),
	},
	{
		title: 'VOICE OVERS',
		description:
			'Professional voice overs in 24 hours',
		link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./albierobles.png'),
	},
];

export default tutorialsData;
