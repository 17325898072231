const featuredLinksData = {
	communityFeatured: {
		title: 'Featured Talent',
		links: [
			{ link: 'Web Development', href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development' },
			{ link: 'Information Security', href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security' },
			{ link: 'Networking / System Admin', href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin' },
			{ link: 'Video / Animation', href: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation' },
			{ link: 'Bookkeepers / Tax Prep', href: 'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax' },
		],
	},
	productsFeatured: {
		title: 'Featured Skills',
		links: [
			{ link: 'PHP', href: '/marketplace/skill/php' },
			{ link: 'Psychology', href: '/marketplace/skill/psychology' },
			{
				link: 'SQL Development',
				href: '/marketplace/skill/sql-development',
			},
			{ link: 'WordPress', href: '/marketplace/skill/wordpress' },
			{
				link: 'Logo Designers',
				href: '/marketplace/skill/logo-designers',
			},
		],
	},
};

export default featuredLinksData;
