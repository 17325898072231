const campaignsData = [
	{
		campaignThumbnail: require('./hostjane-telegram.jpg'),
		title: 'Telegram channel',
		btnText: 'News',
		btnLink: 'https://t.me/hostjanecom',
	},
	{
		campaignThumbnail: require('./hostjane-discord.jpg'),
		title: 'Fresh thinking on Bluesky',
		btnText: 'Embrace',
		btnLink: 'https://bsky.app/profile/hostjane.com',
	},
	{
		campaignThumbnail: require('./hostjane-subreddit.jpg'),
		title: '/r/HostJane',
		btnText: 'Engage',
		btnLink: 'https://www.reddit.com/r/HostJane/',
	},
];

export default campaignsData;
